import * as Sentry from "@sentry/node";
import "antd/dist/antd.css";
import App from "next/app";
import React from "react";
import ReactGA from "react-ga";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.NODE_ENV === "production",
});

export function reportWebVitals({ id, name, label, value }) {
  ReactGA.event({
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    return <Component {...modifiedPageProps} />;
  }
}

export default MyApp;
